<template>
  <div class="footer">
    <span>MOONTOASTERS™ can not be held responsible for any incidents and will reserve it's right according to the
      <a ref="http://localhost:3000/terms-of-usage">terms of usage</a>
    </span>
  </div>
</template>

<script>
export default {
name: "footer"
}
</script>

<style scoped>

.footer{
  padding: 10px;
  background-color: black;
  color: #587ea3;
}

</style>
