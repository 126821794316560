<template>
  <div class="wrapper">
    <div class="funding">
      <div v-if="!funded">
        <input class="input" v-model="amount" placeholder="Enter amount of LUNA">
        <button class="button" @click="dispatchContribution">Fund</button>
      </div>
      <div v-else class="placestuff">
        Thank you for contributing to our journey to the moon!
      </div>

      <div class="notice">
        Fund us and receive an mu$ka airdrop when the token goes live (soon)!
      </div>
      <span class="total-amount">
        Already backed with {{Math.round(totalFunding * 100) / 100}} LUNA
      </span>
    </div>

    <div class="info-wrapper">
      <span class="title">Latest contributions</span>
      <div class="column-header">
        <div class="amount">
          Amount
        </div>
        <div class="address">
          Address
        </div>
      </div>
      <div class="contributions-wrapper">
        <div v-for="contribution in contributions"
             v-bind:key="contribution.amount"
             :class="{even: contributions.indexOf(contribution)%2}"
             class="contribution">

          <div class="amount">
            {{Math.round(contribution.amount * 100) / 100}} LUNA
          </div>
          <div class="address">
            {{contribution.wallet}}
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Investments",
  data() {
    return {
      totalFunding: null,
      contributions: [],
      amount: 0,
      funded: false
    }
  },
  mounted() {
    this.fetchContributions()
  },
  methods: {
    fetchContributions: async function() {
      const response = await this.$http.get("https://hackathon.gebes.io/fund?amount=5")
      console.log(response.data)
      this.totalFunding = response.data.totalAmountFunded
      this.contributions = response.data.funds
    },
    dispatchContribution: async function() {
      const data = {
      amount: this.amount,
      wallet: "terraDEADBEEFpeNvABaOD4x2pPlucb18f1KIXmUVnun"
    }
      await this.$http.post("https://hackathon.gebes.io/fund", data)
      this.funded = true
      this.fetchContributions()
    }
  }
}
</script>

<style scoped>
.wrapper {
  text-align: left;
}
.funding{
  text-align: center;
  background: url('../../../assets/pattern2.svg');
  width: 100%;
  height: 250px;
  margin: 50px 0;
  color: white;
  font-weight: 999;
}

.total-amount {
  font-size: 40px;
  text-align: center;
}

.input {
  height: 30px;
  font-size: 25px;
  margin-top: 50px;
  margin-bottom: 50px;
  border-style: none;
  -moz-border-radius-bottomright: 5px;
  padding: 0;
  padding-right: 5px;
  text-align: right;
  background-color: rgba(255, 255, 255, 0.8);
}

.button {
  height: 50px;
  border-style: none;
  margin-left: 20px;
  font-size: 25px;
  padding: 5px;
  border-radius: 40px;
}

.button {
  padding: 15px 25px;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: #04AA6D;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px #999;
}

.button:hover {background-color: #3e8e41}

.button:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.info-wrapper {
  margin: 0 auto;
}

.title {
  margin-top: 50px;
  font-size: 25px;
  margin-bottom: 10px;
}

.column-header {
  display: flex;
  font-size: 20px;
}

.contribution {
  display: flex;
  color: #587ea3;
  padding: 3px;
}

.even {
  color: #2c3e50;
}

.address {
  width: 380px;
}

.amount {
  width: 200px;
}

.placestuff {
  height: 130px;
  line-height: 130px;
}
</style>
