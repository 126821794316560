<template>
  <div class="header">
    <div class="main-content">
      <span class="title">mu$ka</span>
      <hr class="divider"/>
      <span class="slogan">the next-gen wallet</span>
    </div>
  </div>
</template>

<script>
export default {
name: "Header"
}
</script>

<style scoped>

.header {
  display: flex;
  justify-content: right;
  height: 100vh;
  background: url('../../assets/pattern.svg');
}

.main-content {
  display: block;
  margin: 30vh auto;
  text-align: center;
}

.divider {
  border: 3px solid black;
  margin-bottom: 25px;
}


.title {
  color: #000000;
  font-size: 90px;
  font-family: ubuntu-title;
  margin-left: 50px;
  letter-spacing: 50px;
}

.slogan {
  color: #000000;
  font-size: 25px;
  font-family: ubuntu-title;
  margin-left: 25px;
  letter-spacing: 25px;
}



</style>
