<template>
  <div id="app">
    <Header/>
    <Description/>
    <TokenScreen/>
    <Footer/>
  </div>
</template>

<script>
import Footer from './components/Footer/Footer'
import Header from "./components/Header/Header";
import TokenScreen from "./components/TokenScreen/TokenScreen";
import Description from "@/components/Description/Description";

export default {
  name: 'App',
  components: {
    Description,
    TokenScreen,
    Header,
    Footer
  }
}
</script>

<style>
@font-face {
  font-family: "ubuntu-title";
  src: local(Ubuntu-Title),   url(./assets/Ubuntu-Title.ttf) format("truetype");
}

html, body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 18px;

}
</style>
