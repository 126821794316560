<template>
  <div class="wrapper">
    <h1 class="title">Buy our token</h1>
    <div class="content">
      <img class="image" src="../../assets/logo.jpeg"/>
      <span class="description">Enjoy cashback in the form of airdrops powered by our affiliates.
      Use mu$ka while being on your favourite DeFi platform and be eligible to claim airdrops by owning the mu$ka token</span>
    </div>
    <Investments/>
  </div>

</template>

<script>
import Investments from "@/components/TokenScreen/components/Investments";
export default {
name: "DonateScreen",
  components: {Investments}
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.title {
  font-family: ubuntu-title;
  font-size: 42px;
}

.description {
  text-align: left;
  margin-top: 100px;
  margin-right: 20px;
}

.image {
  margin: 20px auto;
  width: 400px;
}

.content {
  display: flex;
  width: 70%;
  margin: 0 auto;
}

</style>
