<template>
  <div class="wrapper">
    <h1 class="title">Trust the worlds best wallet</h1>
    <div>We at MOONTOASTERS™ are building the worlds best performing crosschain wallet. Enjoy the realm of DeFi with
      the highest security and get airdrops in return!</div>
    <img class="image" src="../../assets/Screens/All.png"/>

    <div class="list">
      <h3>why mu$ka</h3>
      <ul>
        <li>One secret - all the wallets</li>
        <li>Lean design and easy usage</li>
        <li>Cashback at our affiliate partners</li>
      </ul>
    </div>


  </div>

</template>

<script>
export default {
name: "Description"
}
</script>

<style scoped>
.wrapper {
  height: 100vh;
  padding: 80px
}

.image {
  width: 70%;
}

.title {
  font-family: ubuntu-title;
  font-size: 42px;
}

.list {
  text-align: left;
  width: 50vw;
  margin: 0 auto;
}

</style>
